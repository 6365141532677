import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Menu, Dropdown,Badge } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.scss'

const mapStateToProps = ({ brands }) => ({ brands })
const Brands = ({  dispatch,brands }) => {

  const getBrands = () => {
    dispatch({
      type: 'brands/GET_BRANDS',
    })
    return true
  }
const changeBrand = (selectedBrand) => {
  dispatch({
    type: 'brands/CHANGE_BRAND',
    payload: {
      code: selectedBrand.code,
      name: selectedBrand.name
    },
  })
}
  const brandsSubTitle = <FormattedMessage id="topBar.brands.subTitle" />
  const menu = (
    <Menu selectable={false}>
      <Menu.ItemGroup title={brandsSubTitle}>
        {brands.brands.map((brand,index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Menu.Item key={index} onClick={() => changeBrand(brand)}>
              {brand.name}
            </Menu.Item>
          )
        })}
      </Menu.ItemGroup>
    </Menu>
  )
  return (
    <div>
      <Badge count={brands.brands.length} style={{ backgroundColor: '#161537',marginRight:3,marginTop:-5 }}>
        <Dropdown overlay={menu} trigger={['click']} onClick={getBrands} placement="bottomLeft">
          <div className={styles.dropdown}>
            <i className={`${styles.icon} fe fe-layers`} />
            <span className="d-none d-xl-inline">
              <FormattedMessage id="topBar.brands.title" />
            </span>
          </div>
        </Dropdown>
      </Badge>
    </div>
  )
}

export default connect(mapStateToProps)(Brands)
