// eslint-disable-next-line no-unused-vars
import React from 'react'
import { withRouter } from 'react-router-dom'
import Header from '../../pages/landing/components/Header'
import Footer from '../../pages/landing/components/Footer'
import FooterBar from '../../pages/landing/components/FooterBar'
// import { Helmet } from 'react-helmet'
import '@landing/scss/style.scss'

const LandingLayout = ({ children }) => {

  
  return (
    <div className="landing">
      <Header />
      {children}
      <Footer />
      <FooterBar />
    </div>
  )
}

export default withRouter(LandingLayout)
