import { all, takeEvery, put, call } from 'redux-saga/effects'
import getBrandsData from 'services/brands'
import store from 'store'
import { history } from 'index'
import actions from './actions'

export function* CHANGE_BRAND({ payload }) {
  yield store.set('activeBrand', payload)
  yield put({
    type: 'brands/SET_STATE',
    payload: {
      activeBrand: payload,
    },
  })
  history.push('consents')
}
export function* GET_DATA() {
  const brandsDataResult = yield call(getBrandsData)

  if (brandsDataResult) {
    const brandsData = brandsDataResult.map(brand => {
      return { code: brand.code, name: brand.name }
    })

    const activeBrandStore = store.get('activeBrand') ? store.get('activeBrand') : ''

    yield put({
      type: 'brands/SET_STATE',
      payload: {
        brands: brandsData,
        activeBrand: {
          code: activeBrandStore.code ? activeBrandStore.code : '',
          name: activeBrandStore.name ? activeBrandStore.name : '',
        },
      },
    })
  }
}
export function* GET_BRANDS() {
  const brandsDataResult = yield call(getBrandsData)
  if (brandsDataResult) {
    const brandsData = brandsDataResult.map(brand => {
      return { code: brand.code, name: brand.name }
    })
    yield put({
      type: 'brands/SET_STATE',
      payload: {
        brands: brandsData,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_BRAND, CHANGE_BRAND),
    takeEvery(actions.GET_BRANDS, GET_BRANDS),
    GET_DATA(),
  ])
}
