import localeAntd from 'antd/es/locale/tr_TR'

const messages = {
  'topBar.issuesHistory': 'Geçmiş',
  'topBar.typeToSearch': 'Aramak için yazmaya başlayın ...',
  'topBar.actions': 'İşlemler',
  'topBar.status': 'Durum',
  'topBar.activeBrand': 'Marka:',
  'topBar.profileMenu.hello': 'Merhaba',
  'topBar.profileMenu.billingPlan': 'Fatura Planı',
  'topBar.profileMenu.role': 'Rol',
  'topBar.profileMenu.email': 'E-Posta',
  'topBar.profileMenu.phone': 'Telefon',
  'topBar.profileMenu.editProfile': 'Profili Düzenle',
  'topBar.profileMenu.logout': 'Çıkış',
  'topBar.profileMenu.name': 'Hesap Bilgileri',
  'topBar.profileMenu.iysCode': 'İYS Numarası',
  'topBar.profileMenu.company': 'Firma',
  'topBar.brands.title': 'Markalar',
  'topBar.brands.subTitle': 'Marka Listesi',
  'page.loading': 'Yükleniyor...',
  'login.signIn.title': 'Sistem Girişi',
  'login.signIn.btn.title': 'Giriş Yap',
}

export default {
  locale: 'tr-TR',
  localeAntd,
  messages,
}
