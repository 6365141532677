import actions from './actions'

const initialState = {
  name: '',
  email: '',
  first_name: '',
  last_name: '',
  iys_code: '',
  brands_count: '',
  avatar: '',
  role: '',
  authorized: false,
  loading: false,
  is_admin: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
