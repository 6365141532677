import apiClient from 'services/axios'
import store from 'store'

export async function login(email, password) {
  const data = JSON.stringify({ auth: { email, password } })
  return apiClient
    .post('/user_token', data)
    .then(response => {
      if (response) {
        const { jwt } = response.data
        if (jwt) {
          store.set('accessToken', jwt)
        }
        return response.data.jwt
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  if (store.get('accessToken')) {
    return apiClient
      .get('/info')
      .then(response => {
        if (response) {
          return response.data
        }
        return false
      })
      .catch(err => {
        console.log(err)
        store.remove('accessToken')
        store.remove('activeBrand')
      })
  }
  return false
}

export async function logout() {
  store.remove('accessToken')
  store.remove('activeBrand')
  return true
  // return apiClient
  //   .get('/auth/logout')
  //   .then(() => {
  //     store.remove('accessToken')
  //     store.remove('activeBrand')
  //     return true
  //   })
  //   .catch(err => console.log(err))
}
