import React from "react"

function FooterBar() {
  return (
    <footer className="footer footer-bar">
      <div className="container text-center">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <div className="text-sm-left">
              <p className="mb-0">
                © 2002 - {new Date().getFullYear()}
                <a href="https://www.egebilgi.com.tr" target="noreferer noopenner" className="text-reset ml-2">
                  Egebilgi Yazılım San. Tic. Ltd. Şti.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterBar
