import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import store from 'store'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const adminRoutes = [
  {
    path: '/customers',
    Component: lazy(() => import('pages/customers')),
    exact: true,
  },
]
const privateRoutes = [
  // Dashboards
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard/main')),
    exact: true,
  },
  // Pages
  {
    path: '/brands',
    Component: lazy(() => import('pages/brands')),
    exact: true,
  },
  {
    path: '/retailers',
    Component: lazy(() => import('pages/retailers')),
    exact: true,
  },
  {
    path: '/retailer/add',
    Component: lazy(() => import('pages/retailers/create')),
    exact: true,
  },
  {
    path: '/retailer/:id',
    Component: lazy(() => import('pages/retailers/read')),
    exact: true,
  },
  {
    path: '/retailer/:id/edit',
    Component: lazy(() => import('pages/retailers/update')),
    exact: true,
  },
  {
    path: '/consents',
    Component: lazy(() => import('pages/consents/index')),
    exact: true,
  },
  {
    path: '/consent/add',
    Component: lazy(() => import('pages/consents/create')),
    exact: true,
  },
  {
    path: '/consent/:id',
    Component: lazy(() => import('pages/consents/read')),
    exact: true,
  },
]
const routes = [
  // Auth Pages
  {
    path: '/',
    Component: lazy(() => import('pages/landing')),
    exact: true,
  },
  {
    path: '/paketler',
    Component: lazy(() => import('pages/landing/pages/Packages')),
    exact: true,
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  // Error
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings,user }) => ({routerAnimation: settings.routerAnimation,user})


const Router = ({ history, routerAnimation,user }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}

                    {user.authorized &&
                      privateRoutes.map(({ path, Component, exact }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      ))}
                    {user.is_admin &&
                      adminRoutes.map(({ path, Component, exact }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      ))}
                    {store.get('accessToken') === undefined && <Redirect to="/auth/login" />}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
