/* eslint-disable react/no-unescaped-entities */
import React from "react"
import {Link} from 'react-router-dom'

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="/" className="logo-footer">
              <img src="images/logo-light.png" height={34} alt="" />
            </a>
            <p className="mt-4">
              Türkiye'nin öncü şirketlerinin tercihi. Yüksek kalite. Türkiye'den sunucu hizmeti.
            </p>
          </div>
          <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h4 className="text-light footer-head">Kurumsal</h4>
            <ul className="list-unstyled footer-list mt-4">
              <li>
                <a
                  href="https://www.egebilgi.com.tr/egebilgi-web-yazilim-tasarim-hakkinda"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-foot"
                >
                  <i className="mdi mdi-chevron-right mr-1" /> Hakkımızda
                </a>
              </li>
              <li>
                <a
                  href="https://www.kvkk.info.tr/clients/egebilgi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-foot"
                >
                  <i className="mdi mdi-chevron-right mr-1" /> KVKK
                </a>
              </li>
              <li>
                <a
                  href="https://www.egebilgi.com.tr/banka-hesaplarimiz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-foot"
                >
                  <i className="mdi mdi-chevron-right mr-1" /> Banka Bilgileri
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h4 className="text-light footer-head">Hızlı Ulaşım</h4>
            <ul className="list-unstyled footer-list mt-4">
              <li>
                <a
                  href="https://www.egebilgi.com.tr/izmir-profesyonel-web-uygulama-iletisim"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-foot"
                >
                  <i className="mdi mdi-chevron-right mr-1" /> İletişim
                </a>
              </li>
              <li>
                <Link
                  to="paketler"
                  className="text-foot"
                >
                  <i className="mdi mdi-chevron-right mr-1" /> Paketler
                </Link>
              </li>
              <li>
                <a
                  href="https://www.egebilgi.com.tr/iys-ileti-yonetim-sistemi-resmi-is-ortagi-egebilgi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-foot"
                >
                  <i className="mdi mdi-chevron-right mr-1" /> İYS
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
