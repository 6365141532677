import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

const Footer = ({ settings: { isContentMaxWidth, logo, description, logoIcon } }) => {
  return (
    <div
      className={classNames(style.footer, {
        [style.footerFullWidth]: !isContentMaxWidth,
      })}
    >
      <div className={style.inner}>
        <div className="row">
          <div className="col-md-8">
            <div className={style.copyright}>
              <p className="mb-0">
                <a href="https://www.egebilgi.com.tr" target="_blank" rel="noopener noreferrer">
                  EGEBİLGİ
                </a>{' '}
                | Copyright © {new Date().getFullYear()}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className={style.logo}>
              <div className={style.logo__letter}>{logoIcon}</div>
              <div className={style.logo__name}>{logo}</div>
              <div className={style.logo__descr}>{description}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
