import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { Dropdown } from 'antd'
import PerfectScrollbar from 'react-perfect-scrollbar'
import apiClient from 'services/axios'
import List from './List'
import styles from './style.module.scss'

const Search = () => {
    const [users,setUsers]=useState([])
    const [search, setSearch] = useState('')
    const searchRecipient = (e) => {
      const {value} = e.target

      setSearch(value)
      const url = `/consents/search`
      if(value.length>2){
        apiClient
          .post(url, {
            recipient: value,
          })
          .then(response => {
            const usersData = response.data.data.map(user => {
              const data = {
                id: user.id,
                recipient: user.recipient,
                consent_type: user.consent_type,
                img:
                  user.recipient_type === 'BIREYSEL'
                    ? '/resources/images/bireysel.png'
                    : '/resources/images/tacir.png',
              }
              return data
            }) 
            setUsers(usersData)
          })
          .catch(error => console.log(error))
      }
    }
  const menu = (
    <>
      {search.length > 2 && (
        <div className="card air__utils__shadow width-300">
          <div className="card-body pt-3 pb-3 pl-3 pr-3">
            <PerfectScrollbar>
              <List go={()=>{setSearch('')}} users={users} />
            </PerfectScrollbar>
          </div>
        </div>
      )}
    </>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} enter placement="bottomLeft">
      <div className={styles.searchContainer}>
        <i className={`${styles.searchIcon} fe fe-search`} />
        <input
          className={styles.searchInput}
          onChange={searchRecipient}
          type="text"
          value={search}
          placeholder="Ara..."
        />
      </div>
    </Dropdown>
  )
}

export default injectIntl(Search)
