import actions from './actions'

const initialState = {
    activeBrand:{
      code:'',
      name:''
    },
    brands:[]
}

export default function brandReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
