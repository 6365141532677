import React from 'react'
import {Link} from 'react-router-dom'
import { Empty } from 'antd'
import style from './style.module.scss'


const List = (props) => {
  const {users,go} = props
  const goUrl = () =>{
    go(true)
  }

  return (
    <div className={style.listWrapper}>
      {users.length > 0 ? (
        <div>
          <div className="text-uppercase font-size-12 mb-2 text-gray-6">Alıcılar</div>
          <ul className="list-unstyled">
            {users.map(user => (
              <li key={user.id} className={style.item}>
                <Link to={`/consent/${user.id}`} onClick={goUrl} className={style.itemLink}>
                  <div className="kit__utils__avatar mr-3">
                    <img src={user.img} alt={user.recipient} />
                  </div>
                  <div>
                    <div className="text-blue">{user.recipient}</div>
                    <div className="text-muted">{user.consent_type}</div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  )
}

export default List