export default async function getMenuData() {
  return [
    {
      title: 'Gösterge Paneli',
      key: 'dashboard',
      icon: 'fe fe-home',
      url: '/dashboard',
    },
    {
      category: true,
      title: 'MODÜLLER',
    },
    {
      title: 'Markalar',
      key: 'brands',
      icon: 'fe fe-layers',
      url: '/brands',
    },
    {
      title: 'Bayiler',
      key: 'retailers',
      icon: 'fe fe-users',
      url: '/retailers',
    },
    {
      title: 'İzinler',
      key: 'consents',
      icon: 'fe fe-check',
      url: '/consents',
    },
  ]
}
