import apiClient from 'services/axios'
import store from 'store'

export default async function getBrandsData() {
  if (store.get('accessToken')) {
    return apiClient('/brands')
      .then(response => {
        return response.data
      })
      .catch(err => console.log(err))
  }
  return false
}

