import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

const mapStateToProps = ({ brands }) => ({ brands })
const Status = brands => {
  return (
    <div>
      <FormattedMessage id="topBar.activeBrand" />
      <span className="ml-2 p-1 bg-info badge text-white font-size-12 text-uppercase">
        {brands.brands.activeBrand.code ? brands.brands.activeBrand.name : 'Seçilmedi'}
      </span>
    </div>
  )
}

export default connect(mapStateToProps)(Status)
