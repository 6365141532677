import React from "react"
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

const mapStateToProps = ({ user }) => ({ user })

function Header( {user} ) {
  const location = useLocation()
  const forceUpdate= ()=>{
    if(location.pathname==="/paketler"){
      window.location.reload();
    }
  }
  return (
    <>
      <header id="topnav" className="defaultscroll sticky">
        <div className="container">
          <div>
            <a className="logo" href="/">
              <img src="images/logo.png" height={32} alt="" />
            </a>
          </div>
          <div className="buy-button">
            {user.authorized ? (
              <Link to="dashboard" className="btn btn-primary">
                Portal
              </Link>
            ) : (
              <Link to="auth/login" className="btn btn-primary">
                Giriş Yap
              </Link>
            )}
          </div>
          <div className="menu-extras">
            <div className="menu-item">
              <a className="navbar-toggle">
                <div className="lines">
                  <span />
                  <span />
                  <span />
                </div>
              </a>
            </div>
          </div>
          <div id="navigation">
            <ul className="navigation-menu">
              <li>
                <a href="/">Anasayfa</a>
              </li>
              <li>
                <a
                  href="https://www.egebilgi.com.tr/iys-ileti-yonetim-sistemi-resmi-is-ortagi-egebilgi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  iYS Nedir ?
                </a>
              </li>
              <li>
                <Link to="/paketler" onClick={forceUpdate}>
                  Paketler
                </Link>
              </li>
              <li>
                <a
                  href="https://www.egebilgi.com.tr/izmir-profesyonel-web-uygulama-iletisim"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  İletişim
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  )
}

export default connect(mapStateToProps)(Header)
